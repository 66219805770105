import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import ReactSlidingPane from 'react-sliding-pane';

const SlideLinePane: React.FC<any> = ({ isOpen = false, linesData = [], onChange, handleEnableLine }) => {

  const [open, setOpen] = useState(isOpen);
  const [enableAll, setEnableAll] = useState(true);
  const [lines, setLines] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  useEffect(() => {
    setLines(linesData);
    const habilitadas = linesData.filter(l => l.enableFilter).length === linesData.length;
    if (!habilitadas) {
      setEnableAll(false)
    }
  }, [linesData])

  const toggleEnableAll = (enable) => {
    const lineas = [...lines];
    lineas.forEach(line => {
      line.enableFilter = enable;
      handleEnableLine(line, enable);
    });

    setEnableAll(enable);
    setLines(lineas);
  }

  const toggleEnableLine = (zone, index, enable) => {
    const lineas = [...lines];
    lineas[index].enableFilter = enable;
    handleEnableLine(zone, enable);
    setLines(lineas);
  }

  const toggleDetailLine = (index) => {
    const lineas = [...lines];
    lineas[index].details = !lineas[index].details;
    setLines(lineas);
  }

  return (
    <ReactSlidingPane
      className="map-slider-pane line-sliding-pane p-0"
      overlayClassName="custom-overlay-class"
      isOpen={open}
      from="left"
      width="500px"
      title={t('LINES')}
      onRequestClose={() => {
        setOpen(false);
        onChange(false);
      }}
    >
      <React.Fragment>
        <Card.Header style={{ minHeight: '35px', height: '35px', paddingLeft: '10px' }}>
          <div className="clearfix">
            <div className="form-check form-switch">
              <input type="checkbox" className="form-check-input form-check-success" checked={enableAll} onChange={(event) => toggleEnableAll(event.target.checked)} />
              <label className="form-check-label ms-3"><strong>{t('showAllLines')}</strong></label>
            </div>
          </div>
        </Card.Header>
        {
          lines.map((line, index) => (

            <Card className='p-0' key={line.id}>
              <Card.Header style={{ minHeight: '35px', height: '35px', paddingLeft: '10px' }}>
                <div className="form-check form-switch">
                  <input type="checkbox" style={{ marginTop: '5px' }} className="form-check-input" checked={line.enableFilter} onChange={(event) => toggleEnableLine(line, index, event.target.checked)} />
                  <label className="form-check-label ms-3" style={{ marginTop: '7px' }}><strong>{line.name}</strong></label>
                </div>
                <button className='btn btn-sm' style={{ marginRight: '-30px' }} onClick={() => toggleDetailLine(index)}>
                  <i className='fa fa-chevron-down'></i>
                </button>
              </Card.Header>
              {
                !line.details ? <></>
                  :
                  <Card.Body className='p-5'>
                    <div>
                      <div className='row'>
                        <div className='col'>
                          {t('code')}
                        </div>
                        <div className='col'>
                          {t('zone')}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col fw-bolder'>
                          {line.code}
                        </div>
                        <div className='col fw-bolder'>
                          {line.zone.name}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col'>
                          {t('stretch')}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col fw-bolder'>
                          {line.start} - {line.end} ({line.length} km)
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col'>
                          {t('voltageLevel')}
                        </div>
                        <div className='col'>
                          {t('structures')}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col fw-bolder'>
                          {line.voltageLevel} kV
                        </div>
                        <div className='col fw-bolder'>
                          {line.towerCount}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col'>
                          {t('configuration')}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col fw-bolder'>
                          <pre>{line.disposition}</pre>
                        </div>
                      </div>

                    </div>
                  </Card.Body>
              }
            </Card>
          ))
        }
      </React.Fragment>
    </ReactSlidingPane>
  )
}

export { SlideLinePane }
